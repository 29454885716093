import React, { useState } from "react";
import {
  Stack,
  Typography,
  Card,
  Switch,
  Divider,
  CircularProgress,
  Tooltip,
  Button,
} from "@mui/joy";
import { makeStyles } from "@mui/styles";
import { updateProgrammingControlSetting } from "../../store/firebaseActions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";
import MyModal from "../MyModal";
import * as PROGRAMMING from "../../constants/ProgrammingConst";

import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";

/* Own Modules */
import ProductArchiveWidget_Modal from "../../Widgets/ProductArchiveWidget_Modal";
import SendingInvitationWidget from "../../Widgets/SendingInvitationWidget.tsx";

/* stylig */
const useStyles = makeStyles((theme) => ({
  topDiv: {
    marginTop: 20,
  },
}));

/* =================== Program Control Panel ==================== */
function ProgramControlPanel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [lock, setLock] = useState(!props.programData.active || false);
  const [inviteModal, setInviteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [controlSetting, setControlSetting] = useState({
    isPremium: props.programData.isPremium || false,
    landingPage: props.programData.landingPage || false,
    isHistoryAvailable: props.programData.isHistoryAvailable || false,
    releaseAll: props.programData.releaseAll || false,
  });

  const isFix =
    props.programData?.programmingType.type === PROGRAMMING.TYPE.fix || false;

  const isTimeline =
    props.programData?.programmingType.type === PROGRAMMING.TYPE.timeline ||
    false;

  /* check if it is PT programming */
  const isPT = props.programData?.isPT || false;

  /* Handling invite athlete */
  const onInviteAthlete = () => {
    setInviteModal(true);
  };

  const handleInviteModalClose = () => {
    setInviteModal(false);
  };

  /* Handling setting changes */
  const settingChange = (event) => {
    const key = event.target.id; // value set based on the switch ID
    const value = event.target.checked;

    const temp = { ...controlSetting };
    temp[`${key}`] = value;

    setLock(true);
    setIsLoading(true);

    // update the backend,
    updateProgrammingControlSetting({
      programmingUID: props.programUID,
      settings: temp,
    })
      .then(() => {
        setControlSetting(temp);
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Setting Updated!",
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Update Error!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLock(false);
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.topDiv}>
      <ProductArchiveWidget_Modal
        isOpen={showArchiveModal}
        onClose={() => setShowArchiveModal(false)}
        productId={props.programUID}
        productType={props.programData.productType}
      />
      {inviteModal && (
        <MyModal open={inviteModal} onClose={handleInviteModalClose}>
          <SendingInvitationWidget
            title={props.programData.name}
            ofType="toProgramming"
            productType={props.programData.productType}
            productID={props.programUID}
            productOwnerID={props.programData.ownerUID}
            onClose={handleInviteModalClose}
          />
        </MyModal>
      )}
      <Card variant="plain">
        <Box>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2}>
              <Stack direction={"column"}>
                <Typography level="title-md">Control Panel</Typography>
                {isPT && (
                  <Typography level="body-sm">
                    Personal Trainer Programming. it's Invitation only.
                  </Typography>
                )}
              </Stack>
              {(isLoading || props.isLoading) && <CircularProgress size="sm" />}
            </Stack>
            <Button
              color={"danger"}
              onClick={() => setShowArchiveModal(true)}
              disabled={lock}
              endDecorator={<DeleteForeverRoundedIcon />}
            >
              Archive
            </Button>
          </Stack>
        </Box>
        <Divider />
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {!isPT && (
            <>
              {false && (
                <>
                  <Tooltip
                    title="Is this Programming part of Premium Package. [Coming Soon Feature]"
                    arrow
                  >
                    <Typography
                      component={"label"}
                      endDecorator={
                        <Switch
                          disabled={isPT || lock}
                          checked={controlSetting.isPremium}
                          onChange={settingChange}
                          id="isPremium"
                        />
                      }
                    >
                      Is Premium
                    </Typography>
                  </Tooltip>
                  <Divider orientation="vertical" />
                </>
              )}
              <Tooltip
                title="Should this program be visible on your landing page or not."
                arrow
              >
                <Typography
                  component={"label"}
                  endDecorator={
                    <Switch
                      disabled={isPT}
                      checked={controlSetting.landingPage}
                      onChange={settingChange}
                      id="landingPage"
                    />
                  }
                  disabled={lock || isPT}
                >
                  Landing Page
                </Typography>
              </Tooltip>
              <Divider orientation="vertical" />
            </>
          )}
          {!isFix && !isTimeline && (
            <>
              <Tooltip
                title="
                On: New athlete will get all the history of the programming. 
                Off: New athlete will view programming from the time they joined."
                arrow
              >
                <Typography
                  component={"label"}
                  endDecorator={
                    <Switch
                      disabled={isPT || lock}
                      checked={controlSetting.isHistoryAvailable}
                      onChange={settingChange}
                      id="isHistoryAvailable"
                    />
                  }
                  disabled={lock || isPT}
                >
                  Is History Available To New Athletes
                </Typography>
              </Tooltip>
              <Divider orientation="vertical" />
              <Tooltip
                title="
                On: Release all available workouts to athletes. 
                Off: Release workouts on Sunday before week starts (athlete local time)."
                arrow
              >
                <Typography
                  component={"label"}
                  endDecorator={
                    <Switch
                      disabled={lock}
                      checked={controlSetting.releaseAll}
                      onChange={settingChange}
                      id="releaseAll"
                    />
                  }
                  disabled={lock || isPT}
                >
                  Release All Workouts
                </Typography>
              </Tooltip>
              <Divider orientation="vertical" />
            </>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            disabled={lock}
            onClick={onInviteAthlete}
            endDecorator={<MarkEmailUnreadIcon />}
          >
            Invite Athletes
          </Button>
        </Stack>
      </Card>
    </div>
  );
}

export default ProgramControlPanel;
