import { extendTheme } from "@mui/joy/styles";

import { experimental_extendTheme as extendMaterialTheme } from "@mui/material/styles";

export const LemonadeTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: "rgb(230, 200, 50)",
          solidHoverColor: "rgb(230, 200, 50, 0.8)",
          solidHoverBg: "rgb(0, 0, 0)",
          softBg: "rgb(230, 200, 50)",
          outlinedBorder: "rgb(230, 200, 50)",
          outlinedColor: "rgb(230, 200, 50)",
        },
        neutral: {
          solidBg: "rgb(0, 0, 0)",
          outlinedBorder: "rgb(0, 0, 0)",
        },
        lemonadeGray: {
          solidBg: "rgb(57, 63, 76)",
          outlinedBorder: "rgb(57, 63, 76)",
        },
        background: {
          default: "rgb(244, 244, 244)",
        },
      },
    },
  },

  fontFamily: {
    display: "'Open Sans', var(--joy-fontFamily-fallback)",
    body: " 'Open Sans' , var(--joy-fontFamily-fallback)",
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        defaultProps: {
          variant: "solid",
          color: "primary",
        },
      },
    },
  },
  spacing: 8,
});

export const materialTheme = extendMaterialTheme({
  palette: {
    primary: {
      main: "rgb(230, 200, 50)",
    },
    secondary: {
      main: "rgb(0, 0, 0)",
    },
    background: {
      default: "rgb(244, 244, 244)",
    },
  },
  typography: {
    fontFamily: ["Oswald", "museo-sans", "sans-serif"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        defaultProps: {
          variant: "contained",
          color: "primary",
        },
      },
    },
  },
  spacing: 8,
});
