/*
 * Lemonade Training Oy, copy rights, all right reserved.
 */
import React, { useState } from "react";
import { Button, Input, Stack } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { sendInvitationByEmail } from "../store/firebaseActions";
import { notifyActions, NOTIFICATION_TYPE } from "../store/notification-slice";
import WidgetCard from "../components/Cards/WidgetCard";
import {
  InvitationType,
  Invite,
  InviteProductDetails,
} from "@lemonadetraining/types";

/*
 * ======================================================
 * Sending Invitation Widget
 * ======================================================
 */
interface InvitationWidgetProps {
  title: string;
  ofType: InvitationType;
  productType?: string;
  productID?: string;
  productOwnerID?: string;
  onClose?: () => void;
}

const SendingInvitationWidget: React.FC<InvitationWidgetProps> = ({
  title,
  ofType,
  productType,
  productID,
  productOwnerID,
  onClose,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  /* ----- Send invitation handler --------- */
  const onSentInvitationHandler = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    /* make a invitation */
    const invitedProduct: InviteProductDetails = {
      type: productType || "ProgrammingsProducts",
      id: productID || "",
      ownerID: productOwnerID || "",
    };

    const newInvitation: Invite = {
      invitationType: ofType,
      // -----------------------------------
      title: title,
      senderID: user.uid,
      senderName: user.displayName,
      recipientEmail: email,
      // -----------------------------------
      status: "Pending",
      // -----------------------------------
      product: invitedProduct,
    };

    /* send the invitation */
    sendInvitationByEmail(newInvitation)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Invitation sent",
          message: `Invitation sent to ${email}`,
        };
        dispatch(notifyActions.sendNotification(notification));
        setEmail("");
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "OH OH!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
        if (onClose) onClose();
      });
  };

  /* ====================[UI Return]===================== */
  return (
    <WidgetCard title={title || "Send Invites"}>
      <Stack sx={{ mt: 1, mb: 1 }} spacing={2}>
        <Input
          fullWidth
          placeholder="Email"
          id="email"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Button
          variant="solid"
          color="primary"
          fullWidth
          type="submit"
          disabled={email.length === 0}
          onClick={onSentInvitationHandler}
          loading={loading}
        >
          Send Invites
        </Button>
      </Stack>
    </WidgetCard>
  );
};

export default SendingInvitationWidget;
