import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

/* UI */
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  CircularProgress,
  Typography,
} from "@mui/joy";
import Chip from "@mui/joy/Chip";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

/* Own Modules */
import AthleteAvatar from "../AthleteAvatar";
import { InviteStatus, Invite } from "@lemonadetraining/types";
import {
  loadProgrammeWithUID,
  loadCoachProductWithUID,
} from "../../store/firebaseActions";
import { acceptOrDeclineInvitation } from "../../store/firebaseActions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import ProductCard from "../ProductCard/ProductCard";

/*
 * ======================================================
 * InvitationView
 * ======================================================
 */
interface InvitationViewProps {
  invite: Invite;
  direction: "sent" | "received";
}

const InvitationView: React.FC<InvitationViewProps> = ({
  invite,
  direction,
}) => {
  const dispatch = useDispatch();
  const showStatus = invite.status !== "Pending";
  const accepted = invite.status === "Accepted" || false;
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadProduct = async () => {
      if (invite.product?.id) {
        /* if it is programming product */
        if (invite?.product?.type === "ProgrammingsProducts") {
          loadProgrammeWithUID(invite?.product?.id)
            .then((newData) => {
              setProduct(newData);
            })
            .catch((error) => {
              console.log("Can't load the product]->", error.message);
            });
        }
        /* if it is coaching subscription */
        if (invite?.product?.type === "CoachingSubscriptionProducts") {
          loadCoachProductWithUID(invite?.product?.id)
            .then((newData) => {
              setProduct(newData);
            })
            .catch((error) => {
              console.log("Can't load the product]->", error.message);
            });
        }
      }
    };

    // executing use effect
    loadProduct();
  }, [invite?.product?.id]);

  /* accept or reject handler ---------------- */
  const onAcceptOrDecline = (status: InviteStatus) => {
    setLoading(true);

    acceptOrDeclineInvitation(invite?.id, status)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Invitation updated",
          message: `You have ${status} the invitation`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((error) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "OH OH!",
          message: error.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* ----- status dependent  */
  let chipColor: "info" | "warning" | "success" | "danger" = "info";

  switch (invite?.status) {
    case "Pending":
      chipColor = "warning";
      break;
    case "Accepted":
      chipColor = "success";
      break;
    case "Declined":
      chipColor = "danger";
      break;
    default:
      break;
  }

  /* ====================[UI Return]===================== */
  return (
    <Accordion>
      <AccordionSummary>
        {direction === "sent" && (
          <>
            <AthleteAvatar
              AthleteUID={invite?.recipientID}
              Name={invite?.recipientName || "Unknown"}
            />
            <Box sx={{ flex: "1 1 auto" }} />
            <Typography level="body-sm">
              {invite?.title || "No Title"}
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            <Chip variant="solid" size="sm" color={chipColor}>
              {invite?.status}
            </Chip>
          </>
        )}
        {direction === "received" && (
          <>
            <AthleteAvatar
              AthleteUID={invite?.senderID}
              Name={invite?.senderName || "Unknown"}
            />
            {loading && <CircularProgress size="sm" />}
            <Box sx={{ flex: "1 1 auto" }} />
            <Typography level="body-sm">
              {invite?.title || "No Title"}
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            {showStatus && (
              <Chip variant="solid" size="sm" color={chipColor}>
                {invite?.status}
              </Chip>
            )}
            {!showStatus && (
              <Stack direction={"row"} spacing={1}>
                <Chip
                  variant="outlined"
                  color="success"
                  size="sm"
                  endDecorator={<CheckRoundedIcon fontSize="sm" />}
                  onClick={() => onAcceptOrDecline("Accepted")}
                >
                  Accept
                </Chip>
                <Chip
                  variant="outlined"
                  color="danger"
                  size="sm"
                  endDecorator={<CloseRoundedIcon fontSize="sm" />}
                  onClick={() => onAcceptOrDecline("Declined")}
                >
                  Decline
                </Chip>
              </Stack>
            )}
          </>
        )}
      </AccordionSummary>
      {(accepted || direction === "sent") && (
        <AccordionDetails>
          {product && (
            <>
              <ProductCard
                disableOnClick
                disableAddToCart={!accepted && direction !== "received"}
                UID={product?.id}
                data={product.data}
              />
            </>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default InvitationView;
