import { Box, Divider, Typography, Stack, AccordionGroup } from "@mui/joy";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyCard from "../../components/Cards/MyCard";

/* Firebase */
import { collection, onSnapshot, query, where, or } from "firebase/firestore";
import { firebaseDB, COLLECTIONS } from "../../store/firebaseApp";
import InvitationView from "../../components/UserProfile/InvitationView.tsx";

/* Own Modules */
import _DashboardBlankPage from "./_DashboardBlankPage";

/* =================== Invites Page ==================== */
const InvitesPage = () => {
  const user = useSelector((state) => state.user.user);

  /* subscribing to user Invites in database */
  const [sentInvites, setSentInvites] = useState([]);
  const [receivedInvites, setReceivedInvites] = useState([]);

  useEffect(() => {
    const InvitesRef = collection(firebaseDB, COLLECTIONS.Invites);
    const q = query(
      InvitesRef,
      or(
        where("recipientID", "==", user.uid),
        where("senderID", "==", user.uid)
      )
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const sent = [];
      const received = [];
      querySnapshot.docs.forEach((doc) => {
        const invite = { ...doc.data() };
        if (invite.senderID === user.uid) {
          sent.push(invite);
        } else {
          received.push(invite);
        }
      });
      setSentInvites(sent);
      setReceivedInvites(received);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  /* ------- [Returns] --------- */
  return (
    <_DashboardBlankPage title="Invites">
      <Stack
        spacing={2}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <MyCard>
          <Box>
            <Typography level="title-md">Sent Invitations</Typography>
            <Typography level="body-sm">
              See the current invitations you have sent to other Athletes.
            </Typography>
          </Box>
          <Divider />
          <Box>
            {sentInvites.length > 0 && (
              <AccordionGroup
                variant="outlined"
                transition="0.2s ease"
                sx={{
                  borderRadius: "md",
                  [`& .${accordionClasses.root}`]: {
                    "& button:hover": {
                      background: "transparent",
                    },
                  },
                  [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: "transparent",
                  },
                  [`& .${accordionDetailsClasses.content}`]: {
                    boxShadow: (theme) =>
                      `inset 0 1px ${theme.vars.palette.divider}`,
                    [`&.${accordionDetailsClasses.expanded}`]: {
                      paddingBlock: "0.75rem",
                    },
                  },
                }}
              >
                {sentInvites.map((invite) => (
                  <>
                    <InvitationView
                      key={invite.id}
                      direction="sent"
                      invite={invite}
                    />
                  </>
                ))}
              </AccordionGroup>
            )}
            {sentInvites.length === 0 && (
              <Typography level="body-sm">
                You have not sent any invitations yet.
              </Typography>
            )}
          </Box>
        </MyCard>
        <MyCard>
          <Box>
            <Typography level="title-md">Received invitation</Typography>
            <Typography level="body-sm">
              See the current invitations you have received.
            </Typography>
          </Box>
          <Divider />
          <Box>
            {receivedInvites.length > 0 && (
              <AccordionGroup
                variant="outlined"
                transition="0.2s ease"
                sx={{
                  borderRadius: "lg",
                  [`& .${accordionClasses.root}`]: {
                    "& button:hover": {
                      background: "transparent",
                    },
                  },
                  [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: "transparent",
                  },
                  [`& .${accordionDetailsClasses.content}`]: {
                    boxShadow: (theme) =>
                      `inset 0 1px ${theme.vars.palette.divider}`,
                    [`&.${accordionDetailsClasses.expanded}`]: {
                      paddingBlock: "0.75rem",
                    },
                  },
                }}
              >
                {receivedInvites.map((invite) => (
                  <Box key={invite.id}>
                    <InvitationView direction="received" invite={invite} />
                  </Box>
                ))}
              </AccordionGroup>
            )}
            {receivedInvites.length === 0 && (
              <Typography level="body-sm">
                You have not received any invitations yet.
              </Typography>
            )}
          </Box>
        </MyCard>
      </Stack>
    </_DashboardBlankPage>
  );
};

export default InvitesPage;
