import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Stack,
  Divider,
  Option,
  Input,
  Select,
  Button,
} from "@mui/joy";

/* Lemonade npm */
import {
  MovementLibObj,
  MEASURE_TYPES,
  MOVEMENT_CATEGORIES,
} from "@lemonadetraining/types";

/* Own Modules */
import {
  addNewMovement,
  checkNewMovementName,
} from "../../store/movLib-slice.js";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import MyModal from "../MyModal.js";

/*
 * ======================================================
 * Add New Movement Modal
 * ======================================================
 */

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddNewMovement_Modal: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const userUID = useSelector((state) => state.user.user.uid);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [newMovement, setNewMovement] = useState<MovementLibObj>({
    name: "",
    name_lowercase: "",
    type: null,
    measureType: null,
    ownerUID: userUID,
  });

  /* ---- Handlers ---- */

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMovement({
      ...newMovement,
      name: e.target.value,
      name_lowercase: e.target.value.toLowerCase(),
    });

    // validate name is unique
    setLoading(true);
    checkNewMovementName(e.target.value)
      .then((res) => {
        setError(false);
      })
      .catch((err) => {
        console.log("error", err.message);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    if (loading) return;
    if (onClose) onClose();
  };

  const onAddNewMovement = async () => {
    /* ---- Validation ---- */
    if (!newMovement.name || !newMovement.measureType || !newMovement.type) {
      dispatch(
        notifyActions.sendNotification({
          severity: NOTIFICATION_TYPE.error,
          title: "Error",
          message: "Please fill all fields",
        })
      );
      return;
    }

    // -- Add new movement
    setLoading(true);
    try {
      const mov = await addNewMovement(newMovement);
      const notification = {
        severity: NOTIFICATION_TYPE.success,
        title: "Movement Added",
        message: `Successfully added new movement: ${newMovement.name}`,
      };
      dispatch(notifyActions.sendNotification(notification));
      handleClose();
    } catch (error) {
      dispatch(
        notifyActions.sendNotification({
          severity: NOTIFICATION_TYPE.error,
          title: "Failed to add movement",
          message: error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  /* ====================[UI Return]===================== */
  return (
    <MyModal open={open} onClose={handleClose} backgroundColor="white">
      <Stack sx={{ m: 1 }} spacing={2}>
        <Typography>Create New Movement!</Typography>
        <Divider />
        <Stack display="flex" direction="row" spacing={2}>
          <Input
            placeholder="Movement Name"
            error={error}
            helperText="Space or any especial characters are not allowed"
            value={newMovement.name}
            onChange={onNameChange}
          />
          <Select
            placeholder="Measurement Type"
            value={newMovement.measureType}
            onChange={(e, newValue) =>
              setNewMovement({ ...newMovement, measureType: newValue })
            }
          >
            {MEASURE_TYPES.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Category"
            value={newMovement.type}
            onChange={(e, newValue) =>
              setNewMovement({ ...newMovement, type: newValue })
            }
          >
            {MOVEMENT_CATEGORIES.map((cat) => (
              <Option key={cat} value={cat}>
                {cat}
              </Option>
            ))}
          </Select>
        </Stack>
        <Stack
          display="flex"
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems={"center"}
        >
          <Button loading={loading} color="primary" onClick={onAddNewMovement}>
            Add
          </Button>
          <Button color="danger" onClick={handleClose}>
            Cancel
          </Button>
          {error && (
            <Typography color="danger">Movement already exist</Typography>
          )}
        </Stack>
      </Stack>
    </MyModal>
  );
};

export default AddNewMovement_Modal;
