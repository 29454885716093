import React, { useState, useEffect } from "react";
import { Box, Typography, Stack, Card, CardCover } from "@mui/joy";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, useAnimation } from "framer-motion";
import { makeStyles } from "@mui/styles";
import ProductCard from "../components/ProductCard/ProductCard";

/* Icons */
import InputRoundedIcon from "@mui/icons-material/InputRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";

/* Routing */
import { useNavigate } from "react-router-dom";

/* ------- Assets ------ */
import HERO_IMAGE from "../assets/BACKGROUND_LANDINGPAGE.jpg";
import LemonadeTrainingText from "../assets/LOGO_trademark.PNG";
import PhoneScreen1 from "../assets/homepage/LT_APP_PHONEICON.png";
import PhoneScreen2 from "../assets/homepage/LT_APP_PHONEICON_2.png";
import WebAppScreen from "../assets/homepage/Icon_desktop.png";
import PageSection from "../components/HeroPage/PageSection";

/* ------- Lemonade Product ------ */
import { loadCoachProductWithUID } from "../store/firebaseActions";
const lemonadeCoachMonthlyUID =
  process.env.NODE_ENV === "development"
    ? "prod_RXfo0K5fRP68ri"
    : "prod_Reu9I070neN5Ed";

/* ------- Animations ------ */
const picAnimationVariants = {
  loaded: { opacity: 1, scale: 1 },
};

const contentAnimationVariants = {
  initial: { y: +150, backgroundColor: "rgba(255, 255, 255, 1)" },
  scrolled: {
    y: 0,
    backgroundColor: "rgba(255, 255, 255, 0.90)",
  },
};

/* ------- stylig ---- */
const useStyles = makeStyles((theme) => ({
  fullBackground: {
    backgroundImage: `url(${HERO_IMAGE})`,
    backgroundSize: "cover",
    backgroundPosition: "50% 20%",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh", // Changed from height to minHeight
    width: "100%",
    display: "flex",
    paddingTop: theme.spacing(20),
    alignItems: "center",
    flexDirection: "column",
  },
  hereLogo: {
    width: 400,
    height: 150,
    [theme.breakpoints.down("sm")]: {
      width: 250,
      height: 100,
    },
  },
  content: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(2),
    marginTop: theme.spacing(16),
    justifyContent: "center",
    alignItems: "center",
  },
}));

/* ======================================================
 * How is it work timeline
 * ======================================================
 */
const HowItWorksTimeline = () => {
  /* ====================[UI Return]===================== */
  return (
    <Stack
      justifyContent={"space-between"}
      sx={{ width: "90%", justifySelf: "center", ml: "auto", mr: "auto" }}
      marginTop={2}
    >
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <InputRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              Create an account, log in and subscribe (first two weeks for
              free!)
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <HowToRegRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              Set your profile on your Dashboard
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <NoteAltOutlinedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              Create your training plan, add it to your landing page & customize
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <ShareRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              Share your landing page to your customers. Your athletes can
              access the workouts securely through Lemonade App (iOS & Android)
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <EuroRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              We handle all your athletes payments and subscriptions and
              cancellation through connected Stripe account.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="secondary">
              <GroupsRoundedIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography level="body-sm" component="span">
              You get to focus on your coaching & your athletes!
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Stack>
  );
};

/* ======================================================
 * Coach Customers
 * ======================================================
 */
const CoachCustomers = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#2C3E50",
        padding: "50px 0",
        textAlign: "center",
      }}
    >
      <Typography variant="h4" sx={{ color: "#F1C40F", marginBottom: "30px" }}>
        COACH CUSTOMERS
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <Box sx={{ textAlign: "center" }}>
          <img
            src="path/to/charlie-image.jpg"
            alt="Charlie Hodge"
            style={{ borderRadius: "50%", width: "200px", height: "200px" }}
          />
          <Typography variant="h6" sx={{ color: "white", marginTop: "10px" }}>
            Charlie Hodge
          </Typography>
          <Typography sx={{ color: "white" }}>
            CFL2, Semifinal & Masters athlete, affiliate founder
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <img
            src="path/to/elias-image.jpg"
            alt="Elias Huhtinen"
            style={{ borderRadius: "50%", width: "200px", height: "200px" }}
          />
          <Typography variant="h6" sx={{ color: "white", marginTop: "10px" }}>
            Elias Huhtinen
          </Typography>
          <Typography sx={{ color: "white" }}>
            MS, Elite Performance Coach
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <img
            src="path/to/xxx-image.jpg"
            alt="Xxx Xxxxxx"
            style={{ borderRadius: "50%", width: "200px", height: "200px" }}
          />
          <Typography variant="h6" sx={{ color: "white", marginTop: "10px" }}>
            Xxx Xxxxxx
          </Typography>
          <Typography sx={{ color: "white" }}>
            Their title will be mentioned here
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

/*
 * ======================================================
 * Home Page
 * ======================================================
 */
const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const animationControl = useAnimation();
  const contentAnimationControl = useAnimation();

  /* ----- screen size ----- */
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* ----- Animations ----- */
  const imageLoaded = () => {
    animationControl.start("loaded");
  };

  /* ----- Product ----- */
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const loadCoachingSub = async () => {
      const product = await loadCoachProductWithUID(lemonadeCoachMonthlyUID);
      console.log("Product", product);
      setProduct(product);
    };

    return loadCoachingSub();
  }, []);

  /* ----- Scroll Event ----- */
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        contentAnimationControl.start("scrolled");
      } else {
        contentAnimationControl.start("initial");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [contentAnimationControl]);

  /* ====================[UI Return]===================== */
  return (
    <div className={classes.fullBackground}>
      <Toolbar />
      <Stack
        sx={{
          alignItems: "center",
        }}
        spacing={5}
      >
        <motion.div>
          <Card
            variant="plain"
            sx={{
              width: smallScreen ? 320 : 450,
              height: smallScreen ? 110 : 160,
              flexGrow: 1,
              backgroundColor: "transparent",
              borderWidth: 0,
              borderRadius: 0,
              "&:hover": {
                boxShadow: "lg",
              },
            }}
          >
            <CardCover>
              <motion.img
                src={LemonadeTrainingText}
                alt="Lemonade Training Oy"
                initial={{ opacity: 0 }}
                transition={{ duration: 0.6 }}
                animate={animationControl}
                variants={picAnimationVariants}
                onLoad={imageLoaded}
                className={classes.hereLogo}
              />
            </CardCover>
          </Card>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: "white", justifySelf: "center" }}
          >
            Squeeze your potential
          </Typography>
        </motion.div>
      </Stack>
      <motion.div
        className={classes.content}
        initial="initial"
        animate={contentAnimationControl}
        variants={contentAnimationVariants}
      >
        <Stack
          spacing={2}
          direction={"column"}
          alignItems="center"
          sx={{ margin: "0 auto", maxWidth: "60%" }}
        >
          <Typography level="h2" sx={{ color: "black", justifySelf: "center" }}>
            Smart coaching starts here, Coach!
          </Typography>
          <Typography
            level="body-sm"
            sx={{
              color: "black",
              justifySelf: "center",
              margin: "0 auto",
              maxWidth: "60%",
            }}
          >
            Training plan programming, athletes’ workout data and journal, video
            function, customer data, billing connection, and much more. All on
            the same platform, same application.
          </Typography>
          <Typography
            level="body-sm"
            sx={{
              color: "black",
              justifySelf: "center",
              margin: "0 auto",
              maxWidth: "60%",
            }}
          >
            Treeniohjelmien luonti ja jakaminen, urheilijoiden treenien kirjaus
            ja seuranta, asiakasluettelo, laskutusyhteys ja paljon muuta. Kaikki
            yhdellä alustalla.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
            }}
          >
            <motion.img
              src={PhoneScreen1}
              alt="Phone Screen 1"
              initial={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
              animate={animationControl}
              variants={picAnimationVariants}
              onLoad={imageLoaded}
              style={{ width: "18%", height: "18%" }}
            />
            <motion.img
              src={PhoneScreen2}
              alt="Phone Screen 2"
              initial={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
              animate={animationControl}
              variants={picAnimationVariants}
              onLoad={imageLoaded}
              style={{ width: "18%", height: "18%" }}
            />
          </Stack>
          <motion.img
            src={WebAppScreen}
            alt="Web App Screen"
            initial={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            animate={animationControl}
            variants={picAnimationVariants}
            onLoad={imageLoaded}
            style={{ width: "30%", height: "30%" }}
          />
          <PageSection>
            <HowItWorksTimeline />
          </PageSection>
          <PageSection>
            <Stack
              direction={smallScreen ? "column" : "row"}
              justifyContent={"space-between"}
              sx={{
                width: "90%",
                justifySelf: "center",
                ml: "auto",
                mr: "auto",
              }}
            >
              <Box justifyContent={"center"} alignSelf={"center"}>
                <Typography variant="h1" fontSize={40}>
                  Activate your coach platform at Lemonade
                </Typography>
              </Box>
              <Box>
                {product && (
                  <ProductCard
                    disableOnClick
                    UID={product?.id}
                    data={product?.data}
                  />
                )}
                {!product && <Typography level="h4">Coming Soon...</Typography>}
              </Box>
            </Stack>
          </PageSection>
        </Stack>
      </motion.div>
    </div>
  );
};

export default HomePage;
