import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import SiteLayout from "./layout/SiteLayout";
import HomePage from "./pages/HomePage";
import ViewWorkoutProgramme, {
  loader as WorkoutProgramLoader,
} from "./pages/ViewWorkoutProgramme";
import { movLibActions, updateMovLib } from "./store/movLib-slice";
import Notification from "./components/Notification";
import CheckoutModal from "./components/Cart/CheckoutModal";
import Sidebar from "./layout/Sidebar";

/* own modals */
import IsAuthenticated from "./utils/IsAuthenticated";

// ---- [Pages]
import _DashboardHeader from "./pages/_dashboard/_DashboardHeader";
import OverViewPage from "./pages/_dashboard/OverViewPage";
import ProfilePage from "./pages/_dashboard/ProfilePage";
import InvitesPage from "./pages/_dashboard/InvitesPage";
import ShoppingCartPage from "./pages/_dashboard/ShoppingCartPage";
import PaymentsAndSubscriptions from "./pages/_dashboard/PaymentsAndSubscriptions";
import WorkoutLibrary from "./pages/_dashboard/WorkoutLibrary";
import { movementCollection } from "./store/firebaseApp";
import { getDocs, onSnapshot, query } from "firebase/firestore";

const isAuthenticatedLoader = () => {
  const isAuthenticated = IsAuthenticated();
  if (!isAuthenticated) {
    return redirect("/login");
  }

  return null;
};

const router = createBrowserRouter([
  {
    Component: SiteLayout,
    children: [
      {
        path: "landing-page/:page",
        async lazy() {
          const LandingPage = await import("./pages/LandingPage");
          return { Component: LandingPage.default, loader: LandingPage.loader };
        },
      },
      {
        path: "payment-status",
        async lazy() {
          const PaymentStatus = await import("./pages/PaymentStatus");
          return { Component: PaymentStatus.default };
        },
      },
      {
        path: "Terms-and-conditions",
        async lazy() {
          const TermsAndCondition = await import("./pages/TermsAndCondition");
          return { Component: TermsAndCondition.default };
        },
      },
      {
        path: "privacy-policy",
        async lazy() {
          const PrivacyPolicy = await import("./pages/PrivacyPolicy");
          return { Component: PrivacyPolicy.default };
        },
      },
      {
        path: "lemonade-signature",
        async lazy() {
          const LemonadeSignaturePage = await import(
            "./pages/LemonadeSignaturePage"
          );
          return { Component: LemonadeSignaturePage.default };
        },
      },
      {
        path: "About-Us",
        async lazy() {
          const AboutUs = await import("./pages/AboutUs");
          return { Component: AboutUs.default };
        },
      },
      { path: "/", element: <HomePage /> },
    ],
  },
  {
    path: "dashboard/*",
    loader: isAuthenticatedLoader,
    children: [
      {
        element: <Sidebar />,
        children: [
          {
            index: true,
            element: <OverViewPage />,
          },
          { path: "shopping-cart", element: <ShoppingCartPage /> },
          { path: "workout-library", element: <WorkoutLibrary /> },
          {
            path: "coach-portal",
            async lazy() {
              const CoachPortalPage = await import(
                "./pages/_dashboard/CoachPortalPage"
              );
              return { Component: CoachPortalPage.default };
            },
          },
          {
            path: "coach-programmings",
            async lazy() {
              const CoachProgramming = await import(
                "./pages/_dashboard/CoachProgramming"
              );
              return { Component: CoachProgramming.default };
            },
          },
          {
            path: "coach-PT",
            async lazy() {
              const PersonalTrainerPage = await import(
                "./pages/_dashboard/PersonalTrainerPage"
              );
              return { Component: PersonalTrainerPage.default };
            },
          },
          {
            path: "coach-archived",
            async lazy() {
              const coachArchived = await import(
                "./pages/_dashboard/CoachProgrammingArchived"
              );
              return { Component: coachArchived.default };
            },
          },
          { path: "payments", element: <PaymentsAndSubscriptions /> },
          { path: "invites", element: <InvitesPage /> },
          { path: "profile", element: <ProfilePage /> },
          {
            path: "admin-portal",
            async lazy() {
              const AdminPortal = await import(
                "./pages/_dashboard/AdminPortal"
              );
              return { Component: AdminPortal.default };
            },
          },
          {
            path: "workout-programme/:UID",
            element: <ViewWorkoutProgramme />,
            loader: WorkoutProgramLoader,
          },
        ],
      },
    ],
  },
  {
    path: "forgot-password",
    async lazy() {
      const ForgotPasswordPage = await import("./pages/ForgotPasswordPage");
      return { Component: ForgotPasswordPage.default };
    },
  },
  {
    path: "register",
    async lazy() {
      const SignupPage = await import("./pages/SignupPage");
      return { Component: SignupPage.default };
    },
  },
  {
    path: "login",
    async lazy() {
      const LoginPage = await import("./pages/LoginPage");
      return { Component: LoginPage.default };
    },
  },
]);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("[APP ENV]", process.env.NODE_ENV);
    // write a query snapshot to get all the movements
    // and store it in the redux store
    const q = query(movementCollection);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const movements = [];
      querySnapshot.docs.forEach((doc) => {
        movements.push({ id: doc.id, ...doc.data() });
      });
      dispatch(updateMovLib(movements));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <CheckoutModal />
      <Notification />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
